import React from 'react'
import { Content } from 'gerami'
import GatsbyImage from 'gatsby-image'
import { FaTv } from 'react-icons/fa'

import { HomeQuery } from '../../../../../../graphql-types'
import Anchor from '../../../../../shared/components/anchor/anchor'

type HomeLiveTvProps = Exclude<
  Exclude<HomeQuery['homePageInfo'], null | undefined>['frontmatter'],
  null | undefined
>['liveTv']

const HomeLiveTv: React.FC<HomeLiveTvProps> = ({ cover, url }) => {
  return (
    <div className="padding-vertical-very-big">
      <Content
        transparent
        size="XL"
        className="margin-vertical-very-big padding-very-big center"
      >
        <Anchor
          className="full-width"
          to={url!}
          target="_blank"
          rel="noopener nofollow"
          style={{ boxShadow: `none` }}
          title={`Watch Live TV`}
        >
          <Content style={{ overflow: `hidden` }}>
            <GatsbyImage
              fluid={{
                aspectRatio: 16 / 9,
                src: `${cover!}?nf_resize=fit&w=776`,
                srcSet: `
                ${cover!}?nf_resize=fit&w=776 776w,
                ${cover!}?nf_resize=fit&w=548 548w,
                ${cover!}?nf_resize=fit&w=320 320w
              `,
                sizes: `
                (min-width: 36em) 33.3vw,
                100vw
              `
              }}
              className="bg-whitish"
            />
          </Content>
        </Anchor>

        <Anchor
          className="inline-block margin-top-very-big gerami-button"
          to={url!}
          target="_blank"
          rel="noopener nofollow"
        >
          <FaTv className="margin-right-big inline-block middle" />
          <span className="inline-block middle">Watch Live TV</span>
        </Anchor>
      </Content>
    </div>
  )
}

export default HomeLiveTv
