import React, { useMemo } from 'react'
import { Parallax } from 'react-parallax'
import GatsbyImage from 'gatsby-image'
import { Content } from 'gerami'
import * as _ from 'lodash'

import './home-quad-links.scss'
import { HomeQuery } from '../../../../../../graphql-types'
import Anchor from '../../../../../shared/components/anchor/anchor'

type HomeQuadLinksProps = Exclude<
  Exclude<HomeQuery['homePageInfo'], null | undefined>['frontmatter'],
  null | undefined
>['quadLinks'] & {
  onlyTop?: boolean
}

const HomeQuadLinks: React.FC<HomeQuadLinksProps> = ({
  topLeftCover,
  topLeftUrl,
  topLeftTitle,
  topRightCover,
  topRightUrl,
  topRightTitle,
  bottomLeftCover,
  bottomLeftUrl,
  bottomLeftTitle,
  bottomRightCover,
  bottomRightUrl,
  bottomRightTitle,
  onlyTop = false
}) => {
  const bg = useMemo(
    () =>
      [topLeftCover!, topRightCover!, bottomLeftCover, bottomRightCover][
        _.random(0, 3)
      ],
    []
  )

  return (
    <div className="home-quad-links">
      <Parallax bgImage={`${bg}?nf_resize=fit&w=1080`} strength={300}>
        <div className="home-quad-links-overlay inset-shade-L inset-shade-M inset-shade-S fg-whitish">
          <Content transparent size="4XL">
            <div>
              <HomeQuadLinksLink
                cover={topLeftCover}
                url={topLeftUrl}
                title={topLeftTitle}
              />
              <HomeQuadLinksLink
                cover={topRightCover}
                url={topRightUrl}
                title={topRightTitle}
              />
            </div>
            {onlyTop ? null : (
              <div>
                <HomeQuadLinksLink
                  cover={bottomLeftCover}
                  url={bottomLeftUrl}
                  title={bottomLeftTitle}
                />
                <HomeQuadLinksLink
                  cover={bottomRightCover}
                  url={bottomRightUrl}
                  title={bottomRightTitle}
                />
              </div>
            )}
          </Content>
        </div>
      </Parallax>
    </div>
  )
}

export default HomeQuadLinks

type HomeQuadLinksLinkProps = {
  cover?: string | null
  url?: string | null
  title?: string | null
}

const HomeQuadLinksLink: React.FC<HomeQuadLinksLinkProps> = ({
  cover,
  url,
  title
}) => {
  return (
    <Anchor to={url!} className="home-quad-links-link">
      <GatsbyImage
        fluid={{
          aspectRatio: 4 / 3,
          src: `${cover!}?nf_resize=fit&w=598`,
          srcSet: `
            ${cover!}?nf_resize=fit&w=598 598w,
            ${cover!}?nf_resize=fit&w=459 459w,
            ${cover!}?nf_resize=fit&w=320 320w
          `,
          sizes: `
            (min-width: 36em) 33.3vw,
            100vw
          `
        }}
        className="bg-whitish"
      />
      <h1>{title!}</h1>
    </Anchor>
  )
}
