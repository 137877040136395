import React from 'react'
import { Block, Content } from 'gerami'
import Markdown from 'markdown-to-jsx'
import { AiOutlineBulb } from 'react-icons/ai'

import { HomeQuery } from '../../../../../../graphql-types'
import Anchor from '../../../../../shared/components/anchor/anchor'

type HomeAboutProps = Exclude<
  Exclude<HomeQuery['homePageInfo'], null | undefined>['frontmatter'],
  null | undefined
>['about']

const HomeAbout: React.FC<HomeAboutProps> = ({
  title,
  description,
  btnText,
  btnUrl
}) => {
  return (
    <div>
      <Content
        transparent
        size="XL"
        className="left margin-vertical-very-big padding-vertical-very-big"
      >
        <Block first last={!(description || (btnText && btnUrl))}>
          <h1 className="">{title}</h1>
        </Block>

        <Block last={!(btnText && btnUrl)}>
          <article
            className="left fg-blackish"
            style={{ fontFamily: 'inherit' }}
          >
            <Markdown>{description}</Markdown>
          </article>
        </Block>

        {!(btnText && btnUrl) ? null : (
          <Block last>
            <Anchor
              to={btnUrl}
              className="gerami-button gerami-button-primary margin-top-very-big"
            >
              <AiOutlineBulb className="margin-right-normal inline-block middle" />
              <span className="inline-block middle">{btnText}</span>
            </Anchor>
          </Block>
        )}
      </Content>
    </div>
  )
}

export default HomeAbout
