import React from 'react'
import { Parallax } from 'react-parallax'
import { Block, Content } from 'gerami'
import Markdown from 'markdown-to-jsx'
import { FiShoppingCart } from 'react-icons/fi'

import './home-shop.scss'
import { HomeQuery } from '../../../../../../graphql-types'
import Anchor from '../../../../../shared/components/anchor/anchor'

type HomeShopProps = Exclude<
  Exclude<HomeQuery['homePageInfo'], null | undefined>['frontmatter'],
  null | undefined
>['shop']

const HomeShop: React.FC<HomeShopProps> = ({
  bg,
  title,
  description,
  btnText,
  btnUrl
}) => {
  return (
    <div className="home-shop">
      <Parallax bgImage={`${bg!}?nf_resize=fit&w=1080`} strength={300}>
        <div className="home-shop-overlay inset-shade-L inset-shade-M inset-shade-S padding-vertical-very-big fg-whitish">
          <Content
            transparent
            size="XL"
            className="center margin-vertical-very-big padding-vertical-very-big"
          >
            <Block first last={!(description || (btnText && btnUrl))}>
              <h1 className="fg-white">{title}</h1>
            </Block>

            <Block last={!(btnText && btnUrl)}>
              <article
                className="center fg-whitish"
                style={{ fontFamily: 'inherit' }}
              >
                <Markdown>{description}</Markdown>
              </article>
            </Block>

            {!(btnText && btnUrl) ? null : (
              <Block last>
                <Anchor
                  to={btnUrl}
                  target="_blank"
                  rel="noopener nofollow"
                  className="gerami-button gerami-button-primary margin-top-very-big"
                >
                  <FiShoppingCart className="margin-right-big inline-block middle" />
                  <span className="inline-block middle">{btnText}</span>
                </Anchor>
              </Block>
            )}
          </Content>
        </div>
      </Parallax>
    </div>
  )
}

export default HomeShop
