import React from 'react'
import { AccordionSlider } from 'gerami'
import { FaPodcast, FaRegNewspaper, MdPlaylistPlay } from 'react-icons/all'
import _ from 'lodash'

import './home-media.scss'
import { HomeQuery } from '../../../../../../graphql-types'
import Anchor from '../../../../../shared/components/anchor/anchor'

type HomeMediaProps = Exclude<
  Exclude<HomeQuery['homePageInfo'], null | undefined>['frontmatter'],
  null | undefined
>['media']

const HomeMedia: React.FC<HomeMediaProps> = ({
  isBlogEnabled,
  blogCover,
  isPodcastsEnabled,
  podcastsCover,
  isVideosEnabled,
  videosCover
}) => {
  const media = _.compact([
    !isBlogEnabled
      ? null
      : {
          cover: `${blogCover}?nf_resize=fit&h=420`,
          icon: <FaRegNewspaper />,
          title: `Blog`,
          btnText: `Read Articles`,
          btnPath: `/blog`
        },
    !isPodcastsEnabled
      ? null
      : {
          cover: `${podcastsCover}?nf_resize=fit&h=420`,
          icon: <FaPodcast />,
          title: `Podcasts`,
          btnText: `Listen to Podcasts`,
          btnPath: `/podcasts`
        },
    !isVideosEnabled
      ? null
      : {
          cover: `${videosCover}?nf_resize=fit&h=420`,
          icon: <MdPlaylistPlay />,
          title: `Videos`,
          btnText: `Open Video Playlists`,
          btnPath: `/videos`
        }
  ])
  return (
    <div className="home-media">
      <AccordionSlider
        imgs={media.map(
          ({ cover, icon, title, btnText, btnPath }) =>
            ({
              src: cover,
              caption: (
                <>
                  <span className="block h0">{icon}</span>
                  <span className="block h2">{title}</span>

                  <Anchor
                    className="gerami-button gerami-button-primary"
                    to={btnPath}
                  >
                    {btnText}
                  </Anchor>
                </>
              )
            } as any)
        )}
      />
    </div>
  )
}

export default HomeMedia
