import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Block, Content, Yoga } from 'gerami'
import { IoMdCalendar } from 'react-icons/io'

import { HomeQuery, HomeSchedulesQuery } from '../../../../../../graphql-types'
import Anchor from '../../../../../shared/components/anchor/anchor'

type HomeSchedulesProps = Exclude<
  Exclude<HomeQuery['homePageInfo'], null | undefined>['frontmatter'],
  null | undefined
>['schedules']

const HomeSchedules: React.FC<HomeSchedulesProps> = ({ maxList }) => {
  const data = useStaticQuery<HomeSchedulesQuery>(query)

  const schedules = data?.featuredSchedules.nodes.slice(0, maxList || 3)

  return (
    <div className="padding-vertical-very-big">
      <Content
        transparent
        size="3XL"
        className="margin-vertical-normal padding-vertical-very-big center"
      >
        <Block first>
          <h1 className="padding-top-none">Schedules</h1>
        </Block>
        <Block>
          <hr />
        </Block>
        <Block last>
          {!schedules.length ? (
            <div className="fg-blackish">No featured schedules found.</div>
          ) : (
            schedules.map(({ frontmatter, excerpt, fields }, i) => (
              <Yoga key={i} maxCol={2}>
                <div
                  className="middle bold fg-blackish"
                  style={{ textTransform: 'uppercase' }}
                >
                  {frontmatter?.happensOn!}
                </div>

                <h4 className="middle left padding-top-small">
                  <Anchor to={fields?.slug!}>
                    {frontmatter?.title!}
                    <span className="subtitle font-L">
                      <span
                        className="inline-block padding-horizontal-normal"
                        style={{ opacity: 0.7 }}
                      >
                        @
                      </span>
                      {frontmatter?.location!}
                    </span>
                  </Anchor>
                  <div className="subtitle font-S">{excerpt}</div>
                </h4>
              </Yoga>
            ))
          )}
        </Block>
        <Block first last>
          <Anchor to="/schedules" className="gerami-button">
            <IoMdCalendar className="margin-right-big inline-block middle" />
            <span className="inline-block middle">See All Schedules</span>
          </Anchor>
        </Block>
      </Content>
    </div>
  )
}

export default HomeSchedules

const query = graphql`
  query HomeSchedules {
    featuredSchedules: allMarkdownRemark(
      filter: {
        frontmatter: { template: { eq: "schedule" }, isFeatured: { eq: true } }
      }
      sort: { fields: [frontmatter___title], order: [ASC] }
    ) {
      nodes {
        frontmatter {
          title
          happensOn
          location
        }
        excerpt
        fields {
          slug
        }
      }
    }
  }
`
