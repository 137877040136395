import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { HomeQuery } from '../../../../graphql-types'
import SEO from '../../../shared/components/seo/seo'
import Layout from '../../../shared/components/layout/layout'
import HomeLead from './components/home-lead/home-lead'
import HomeAbout from './components/home-about/home-about'
import HomeShop from './components/home-shop/home-shop'
import HomeLiveTv from './components/home-live-tv/home-live-tv'
import HomeQuadLinks from './components/home-quad-links/home-quad-links'
import HomeSchedules from './components/home-schedules/home-schedules'
import HomeMedia from './components/home-media/home-media'
import EmailRegistration from '../../../shared/components/email-registration/email-registration'

type HomeProps = {}

const Home: React.FC<HomeProps> = ({}) => {
  const data = useStaticQuery<HomeQuery>(query)
  const { lead, about, shop, liveTv, quadLinks, schedules, media } =
    data.homePageInfo?.frontmatter ?? {}

  return (
    <>
      <SEO title="Home" />

      <Layout>
        {!lead?.isEnabled ? null : <HomeLead {...lead} />}
        {!about?.isEnabled ? null : <HomeAbout {...about} />}
        {!shop?.isEnabled ? null : <HomeShop {...shop} />}
        {!liveTv?.isEnabled ? null : <HomeLiveTv {...liveTv} />}
        {!quadLinks?.isEnabled ? null : <HomeQuadLinks {...quadLinks} />}
        {!schedules?.isEnabled ? null : <HomeSchedules {...schedules} />}
        {!media?.isEnabled ? null : <HomeMedia {...media} />}
        <EmailRegistration />
      </Layout>
    </>
  )
}

export default Home

const query = graphql`
  query Home {
    homePageInfo: markdownRemark(fields: { slug: { eq: "/info/home-page/" } }) {
      frontmatter {
        lead {
          isEnabled
          bg
          title
          description
          btnText
          btnUrl
        }
        about {
          isEnabled
          title
          description
          btnText
          btnUrl
        }
        shop {
          isEnabled
          bg
          title
          description
          btnText
          btnUrl
        }
        liveTv {
          isEnabled
          cover
          url
        }
        quadLinks {
          isEnabled
          topLeftCover
          topLeftUrl
          topLeftTitle
          topRightCover
          topRightUrl
          topRightTitle
          bottomLeftCover
          bottomLeftUrl
          bottomLeftTitle
          bottomRightCover
          bottomRightUrl
          bottomRightTitle
        }
        schedules {
          isEnabled
          maxList
        }
        media {
          isEnabled
          isBlogEnabled
          blogCover
          isPodcastsEnabled
          podcastsCover
          isVideosEnabled
          videosCover
        }
      }
    }
  }
`
